@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/raleway-v22-latin-regular.woff2) format('woff2'), url(assets/fonts/raleway-v22-latin-regular.woff) format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: italic;
  font-weight: 400;
  src: url(assets/fonts/raleway-v22-latin-italic.woff2) format('woff2'), url(assets/fonts/raleway-v22-latin-italic.woff) format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: url(assets/fonts/raleway-v22-latin-300.woff2) format('woff2'), url(assets/fonts/raleway-v22-latin-300.woff) format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: url(assets/fonts/raleway-v22-latin-500.woff2) format('woff2'), url(assets/fonts/raleway-v22-latin-500.woff) format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  src: url(assets/fonts/raleway-v22-latin-600.woff2) format('woff2'), url(assets/fonts/raleway-v22-latin-600.woff) format('woff');
}

@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/raleway-v22-latin-700.woff2) format('woff2'), url(assets/fonts/raleway-v22-latin-700.woff) format('woff');
}
