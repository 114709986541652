body {
  font-family: 'Raleway', sans-serif !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  height: 100vh;
}

:root {
  --vermelho-tenda: #e4002b;
  --azul-tenda: #003e62;
  --white-bg: #F5F5F5;
  --cinza-tenda: #394452;
  --cinza-claro-tenda: #A5ABB3;
  --cinza-escuro-tenda: #2C3A4B;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.button {
  line-height: 19px;
  font-weight: 600 !important;
  padding: 12px 24px;
  text-align: center;
  border-radius: 100px;
  width: 100%;
  font-size: 16px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background .2s;
}

.button_red {
  color: #FFFFFF !important;
  background: var(--vermelho-tenda);
}

.button_yellow {
  color: var(--azul-tenda);
  background: #F6CD3A;
  font-weight: bold !important;
}

.button_yellow:hover {
  background: #f8da6d;
}

.button:disabled {
  background: #CCCCCC;
}

.button__red__outline {
  background: none;
  padding: 10px 22px;
  text-align: center;
  color: #d0021b;
  text-decoration: none;
  border: solid 2px #d0021b;
}

.button__red__outline:hover {
  text-decoration: none;
  color: #d0021b;
}

a:hover {
  text-decoration: none;
}

.oculto {
  display: none;
}

label {
  margin-bottom: 0.2rem;
  font-size: 0.8rem;
}

.custom-select {
  font-size: 0.8rem;
}

.table td, .table th {
  padding: .25rem;
}

.table {
  margin-bottom: 0.25rem;
}

.btn-success.disabled, .btn-success:disabled {
  cursor: default;
}

.btn-danger.disabled, .btn-danger:disabled {
  cursor: default;
}

.btn-left-space {
  margin-left: 5px;
}

.bg-admin {
  background-color: #F4F6F9;
}

.bg-teia {
  background-color: var(--azul-tenda);
}

.bg-teia-white-bottom {
  background: url(assets/img/teia-pattern-white.png) repeat-x bottom;
}

.app-container {
  flex-grow: 1;
}

.footer {
  background-color: var(--azul-tenda);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 22px 64px;
}

.footer.red {
  background-color: #E20721;
}

.flex-grow-2 {
  flex-grow: 2;
}

.spinner-border.thin {
  border-width: .25rem;
}

.color-blue {
  color: var(--azul-tenda) !important;
}

.c-pointer {
  cursor: pointer;
}

.grey-input {
  background: #DFDFDF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15) inset;
  padding: 7px 16px;
  border: none;
  height: 2rem;
  font-size: 1rem;
  border-radius: 1.5rem;
  font-weight: 500;
  color: var(--azul-tenda);
}

.grey-input:focus-visible {
  outline: none;
}

.font-weight-semi-bold {
  font-weight: 600;
}


/*scrollbar*/

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #b3b3b3;
  border-radius: 12px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8c8c8c;
}

::-webkit-scrollbar-track {
  background: var(--white-bg);
  border-radius: 0px;
  box-shadow: inset 0px 0px 0px 0px #F0F0F0;
}

.modal-pai .modal-content .modal-header {
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}

.modal-pai .modal-content {
  margin-top: 25%;
  background-color: #F5F5F5;
  border-radius: .5rem;
}

/*#region switch*/
.form-switch {
  height: 48px;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9098A1;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--vermelho-tenda);
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

.slider.round {
  border-radius: 12px;
}

.slider.round:before {
  border-radius: 50%;
}

/*#endregion*/

/*#region table*/

p-table {
  font-family: 'Raleway', sans-serif !important;
  word-break: break-word;
}

p-table thead {
  font-size: 0.875rem;
}

p-table thead, p-table tbody {
  position: relative;
  border-collapse: separate !important;
  border: none !important;
}

p-table thead:after, p-table tbody:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid #A5ABB3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  pointer-events: none;
  top: 0;
  left: 0;
}

p-table tbody:before {
  border-top: none;
}

.p-datatable .p-datatable-thead > tr > th {
  /*border: none;*/
  padding: .7rem 1.5rem;
}

.p-datatable .p-datatable-tbody > tr:first-child > td {
  /*border: none;*/
}

.p-datatable .p-datatable-tbody > tr > td {
    padding: .7rem 1.5rem;
}

.p-datatable .p-datatable-tbody > tr > td.col-actions button{
  color: var(--azul-tenda) !important;
  margin: 0 5px;
}

.p-datatable .p-datatable-tbody > tr > td.col-actions button span {
  font-size: 1.5rem !important;
}

.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-sortable-column.p-highlight {
  background: #e9ecef;
  color: #2C3A4B !important;
}

i.p-sortable-column-icon {
  margin-left: 0 !important;
  font-size: 0.875rem !important;
  color: #2C3A4B !important;
}

.p-datatable .p-datatable-thead > tr > th .pi-sort-amount-down:before {
  content: "\e913" !important;
}

.p-datatable .p-datatable-thead > tr > th .pi-sort-amount-up-alt:before {
  content: "\e914" !important;
}

.p-datatable .p-datatable-thead > tr > th .pi-sort-alt:before {
  content: "\e915" !important;
}

p-table .p-paginator {
  background: transparent;
  justify-content: flex-start;
  padding: 0.25rem 0;
}

p-table .p-paginator button {
  margin: 0 5px !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  border-radius: 4px !important;
  border: 1px solid #DFE3E8;
  color: var(--azul-tenda) !important;
  background-color: #FAF3F4 !important;
  min-width: 2rem !important;
  height: 2rem !important;
}

p-table .p-paginator .p-paginator-pages button {
  background-color: white !important;
  border: 1px solid #DFE3E8;
  color: var(--azul-tenda) !important;
}

p-table .p-paginator .p-paginator-pages button.p-highlight {
  border-color: #E20721 !important;
  color: #E20721 !important;
}

p-table .p-paginator button.p-disabled {
  background-color: #DDD3D3 !important;
  color: white !important;
  border: none;
}

/*#endregion*/

alert {
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {
  left: 0;
  font-size: 16px;
}

.p-multiselect-panel .p-multiselect-header {
  display: none;
}

.p-splitbutton .p-splitbutton-defaultbutton {
  display: none;
}

.p-datatable .p-datatable-tbody > tr > td.col-actions button {
  margin: 0;
}

.p-calendar{
  border: none;
  max-height: 35px;
}

.p-disabled{
  background-color: #0000000f;
}

.p-button:focus {
  box-shadow: none;
}

.p-inputtext, .p-multiselect, .p-dropdown, .p-password, .p-calendar {
  width: 100%;
}

.disabled-area {
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  height: 114vh;
  top: -90px;
}

/*HUBHAWK*/

.container-fluid {
  padding: 64px;
  padding-right: 57px;
  background-color: #F5F5F5;
}

span > label {
  font-size: 16px;
}

div.row {
  margin-top: 1%;
}

.p-button {
  border: none;
  height: 35px;
  margin-left: 3%;
}

.p-datatable .p-datatable-thead > tr > th {
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  background: #cbcbcb;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 1px;
}

.p-datatable .p-datatable-tbody > tr > td > p {
  font-size: 16px;
  margin: 0px;
}

.p-datatable .p-datatable-tbody > tr > td.col-actions button {
  color: black !important;
}

p-table thead:after, p-table tbody:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  /*border: 1px solid #A5ABB3;*/
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  pointer-events: none;
  top: 0;
  left: 0;
}

/*.p-datatable .p-datatable-tbody > tr:first-child > td {*/
/*  border-top: none;*/
/*}*/

.p-datatable .p-datatable-tbody > tr > td.rowEmpty {
  height: 16px;
  text-align: center;
  padding: 1%;
}

i.p-sortable-column-icon {
  margin-left: 0 !important;
  font-size: 0.875rem !important;
}

p-table .p-paginator {
  background: transparent;
  justify-content: center;
}

p-table .p-paginator .p-paginator-pages button.p-highlight {
  border-color: #666666 !important;
  color: #666666 !important;
}

.p-paginator .p-paginator-current {
  border: 1px solid #666666;
  color: #666666;
  border-radius: 4px;
}

.p-paginator .p-dropdown {
  border-color: #666666 !important;
  color: #666666 !important;
}

.p-confirm-dialog-reject {
  background: red;
}

.p-confirm-dialog-accept {
  background: #007bff !important;
}

.disabled-area p-progressBar {
  width: 60%;
}

.p-splitbutton-menubutton.p-button.p-component.p-button-icon-only {
  background-color: #00000000;
}

.p-button .p-button-icon-left {
  margin-right: 0.3rem;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.p-float-label label, .p-float-label input:focus ~ label {
  font-size: 16px;
}

.title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 5px;
}

p-calendar span .p-button .pi-calendar {
  content: url("../src/assets/svg/icon-calendar.svg");
}

p-dropdown .pi-chevron-down {
  content: url("../src/assets/svg/icon-arrow-blue-down.svg");
}

p-calendar > span > input {
  border: 1px solid #DADEE3;
  border-right: none;
}

p-calendar span .p-button {
  width: 3.15rem;
  background-color: #FFFFFF;
  border: 1px solid #DADEE3;
  border-left: none;
  margin-left: 0;
}

.table-header.row.justify-content-between {
  padding: 0 8px;
  margin-top: 0;
}

.alignAndJustifyEnd {
  display: flex;
  align-items: flex-end;
  padding: 0;
  justify-content: flex-end;
}

.p-paginator {
  background: #ffffff00;
}

.p-inputgroup .p-float-label label {
  left: 50px;
}

.inputGroupButtonFix {
  min-height: 100%;
}

.p-inputgroup > .p-component, .p-inputgroup > .p-float-label > .p-component {
  border-radius: 3px;
}

p-autocomplete .p-autocomplete {
  width: 100%;
}

.splittButtonOptions{
  background-color: #666666;
  border-radius: 4px;
  max-height: 35px;
}

app-perfil .pi-chevron-down:before{
  content: "\e967";
}
