/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
  cursor: pointer;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

/* Loader */

#pre-loader {
  position: fixed;
  background-color: darkred;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 100;
}

.load {
  width: 49px;
  height: 49px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

  .load,
  .load:before,
  .load:after {
    animation: 0.8s infinite ease-in-out;
    -o-animation: 0.8s infinite ease-in-out;
    -ms-animation: 0.8s infinite ease-in-out;
    -webkit-animation: 0.8s infinite ease-in-out;
    -moz-animation: 0.8s infinite ease-in-out;
  }

    .load:before,
    .load:after {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .load:before,
    .load:after {
      content: "";
    }

    .load:before,
    .load:after {
      content: "";
      background-color: rgba(252, 231, 146, 0.97);
      transform: scale(0);
      -o-transform: scale(0);
      -ms-transform: scale(0);
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      animation: cssload-animation 1.195s infinite ease-in-out;
      -o-animation: cssload-animation 1.195s infinite ease-in-out;
      -ms-animation: cssload-animation 1.195s infinite ease-in-out;
      -webkit-animation: cssload-animation 1.195s infinite ease-in-out;
      -moz-animation: cssload-animation 1.195s infinite ease-in-out;
    }

    .load:after {
      animation-delay: 0.5975s;
      -o-animation-delay: 0.5975s;
      -ms-animation-delay: 0.5975s;
      -webkit-animation-delay: 0.5975s;
      -moz-animation-delay: 0.5975s;
    }

@keyframes cssload-animation {
  0% {
    transform: translateX(-100%) scale(0);
  }

  50% {
    transform: translateX(0%) scale(1);
  }

  100% {
    transform: translateX(100%) scale(0);
  }
}

@-o-keyframes cssload-animation {
  0% {
    -o-transform: translateX(-100%) scale(0);
  }

  50% {
    -o-transform: translateX(0%) scale(1);
  }

  100% {
    -o-transform: translateX(100%) scale(0);
  }
}

@-ms-keyframes cssload-animation {
  0% {
    -ms-transform: translateX(-100%) scale(0);
  }

  50% {
    -ms-transform: translateX(0%) scale(1);
  }

  100% {
    -ms-transform: translateX(100%) scale(0);
  }
}

@-webkit-keyframes cssload-animation {
  0% {
    -webkit-transform: translateX(-100%) scale(0);
  }

  50% {
    -webkit-transform: translateX(0%) scale(1);
  }

  100% {
    -webkit-transform: translateX(100%) scale(0);
  }
}

@-moz-keyframes cssload-animation {
  0% {
    -moz-transform: translateX(-100%) scale(0);
  }

  50% {
    -moz-transform: translateX(0%) scale(1);
  }

  100% {
    -moz-transform: translateX(100%) scale(0);
  }
}
